<template>
  <Header />
  <div class="student-container">
    <div class="sidebar">
      <div>
        <ul>
          <li>
            <a>My Profile</a>
          </li>
          <!-- <li>
            <a @click="showContent('Artistic gymnastics')"
              >Artistic gymnastics</a
            >
          </li>
          <li>
            <a @click="showContent('Adult gymnastics')">Adult gymnastics</a>
          </li>
          <li>
            <a @click="showContent('Calisthenics')">Calisthenics</a>
          </li>
          <li>
            <a @click="showContent('Healthy Weight Session')"
              >Healthy Weight Session</a
            >
          </li> -->
        </ul>
      </div>
    </div>
    <div class="scrolling-container">
      <h2>My Profile</h2>
      <form action="#">
        <div class="user-details">
          <div class="input-box">
            <span class="details">Full Name</span>
            <input type="text" placeholder="Amit Yadav" required>
          </div>
          <div class="input-box">
            <span class="details">Aadhar</span>
            <input type="text" placeholder="3488-9837-0445" required>
          </div>
          <div class="input-box">
            <span class="details">Age</span>
            <input type="text" placeholder="14" required>
          </div>
          <div class="input-box">
            <span class="details">Date of Birth</span>
            <input type="text" placeholder="30-08-2010" required>
          </div>
          <div class="input-box">
            <span class="details">Enrollment</span>
            <input type="text" placeholder="Developmental Gymnastics" required>
          </div>
          <div class="input-box">
            <span class="details">Emergency Contact Name</span>
            <input type="text" placeholder="Varsh Yadav" required>
          </div>
          <div class="input-box">
            <span class="details">Father's Name</span>
            <input type="text" placeholder="Sanjiv Kumar" required>
          </div>
          <div class="input-box">
            <span class="details">Father's Profession</span>
            <input type="text" placeholder="Engineer" required>
          </div>
          <div class="input-box">
            <span class="details">Father's Mobile</span>
            <input type="text" placeholder="9990002121" required>
          </div>
          <div class="input-box">
            <span class="details">Father's Email</span>
            <input type="text" placeholder="sanjiv723@gmail.com" required>
          </div>
          <div class="input-box">
            <span class="details">Mother's Name</span>
            <input type="text" placeholder="Sushma Yadav" required>
          </div>
          <div class="input-box">
            <span class="details">Mother's Mobile</span>
            <input type="text" placeholder="9050769481" required>
          </div>
          <div class="input-box">
            <span class="details">Mother's Email</span>
            <input type="text" placeholder="N/A" required>
          </div>
          <div class="input-box">
            <span class="details">Mother's Profession</span>
            <input type="text" placeholder="Housewife" required>
          </div>
          <div class="input-box">
            <span class="details">Address</span>
            <input type="text" placeholder="house no -2093, sector 19 , gugaon" required>
          </div>
          <div class="input-box">
            <span class="details">Pin Code</span>
            <input type="text" placeholder="122022" required>
          </div>
        </div>
        <div class="gender-details">
          <!-- Radio buttons for gender selection -->
          <input type="radio" name="gender" id="dot-1">
          <input type="radio" name="gender" id="dot-2">
          <input type="radio" name="gender" id="dot-3">
          <span class="gender-title">Gender</span>
          <div class="category">
            <!-- Label for Male -->
            <label for="dot-1">
              <span class="dot one"></span>
              <span class="gender">Male</span>
            </label>
            <!-- Label for Female -->
            <label for="dot-2">
              <span class="dot two"></span>
              <span class="gender">Female</span>
            </label>
            <!-- Label for Prefer not to say -->
            <label for="dot-3">
              <span class="dot three"></span>
              <span class="gender">Prefer not to say</span>
            </label>
          </div>
        </div>
        <!-- Submit button -->
        <div class="button">
          <input type="submit" value="Save">
        </div>
      </form>
    </div>
  </div>
  <Footer />
</template>

<script>
import Header from "./Header.vue";
import Footer from "./Footer.vue";
export default {
  data() {
    return {
      user: {
        name: "John Doe",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        mobile: "",
        website: "",
        skype: "",
      },
    };
  },
  methods: {
    submitProfile() {
      // Handle profile submission logic
      console.log("Profile submitted:", this.$data);
    },
  },
  name: "StudentProfile",
  components: {
    Header,
    Footer,
  },
};
</script>

<style>
.student-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.sidebar {
  width: 35%;
  height: 100%;
  background-color: #343a40;
  color: white;
}
.sidebar a {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  text-decoration: none;
  color: white;
  font-size: 20px;
}
.sidebar a:hover {
  background-color: #faf9f6;
  color: black;
  cursor: pointer;
}
.sidebar a i {
  margin-right: 10px;
}
.sidebar a .fas.fa-chevron-right {
  margin-left: auto;
}
.scrolling-container {
  padding-top: 0;
  height: 80%; /* Set the desired maximum height */
  width: 100%;
  overflow-y: auto; /* Enable vertical scrolling */
}
h1 {
  text-align: center;
  margin-bottom: 30px;
  color: hwb(0 84% 15%);
}
h1 {
  text-align: center;
  margin-bottom: 30px;
  color: black(0 99% 0%);
}

.form-group {
  margin-bottom: 20px;
}
/* ////////////////////////////////////////////////////////////////////////////// */

form .user-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0 12px 0;
}

form .user-details .input-box {
  margin-bottom: 15px;
  margin-right: 5%;
  margin-left: 5%;
  width: calc(80% / 2 - 20px);
}
form .input-box span.details {
  display: block;
  margin-right: 10%;
  margin-left: 10%;
  font-weight: 500;
  margin-bottom: 5px;
}
.user-details .input-box input {
  height: 45px;
  width: 100%;
  outline: none;
  font-size: 16px;
  border-radius: 5px;
  padding-left: 15px;
  border: 1px solid #ccc;
  border-bottom-width: 2px;
  transition: all 0.3s ease;
}
.user-details .input-box input:focus,
.user-details .input-box input:valid {
  border-color: #9b59b6;
}
form .gender-details .gender-title {
  font-size: 20px;
  font-weight: 500;
}
form .category {
  display: flex;
  width: 80%;
  margin: 14px 0;
  justify-content: space-between;
}
form .category label {
  display: flex;
  align-items: center;
  cursor: pointer;
}
form .category label .dot {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  margin-right: 10px;
  background: #d9d9d9;
  border: 5px solid transparent;
  transition: all 0.3s ease;
}
#dot-1:checked~.category label .one,
#dot-2:checked~.category label .two,
#dot-3:checked~.category label .three {
  background: #9b59b6;
  border-color: #d9d9d9;
}
form input[type="radio"] {
  display: none;
}
form .button {
  height: 45px;
  margin: 35px 0
}
form .button input {
  height: 100%;
  width: 100%;
  border-radius: 5px;
  border: none;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.3s ease;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
}
form .button input:hover {
  background: linear-gradient(-135deg, #71b7e6, #9b59b6);
}



/* ///////////////////////////////////////////////////////////////////////////// */



@media (max-width: 600px) {
  .Course_container .sidebar {
    width: 37%;
  }
  .Course_container .scrolling-container {
    width: 73%;
  }
  .Course_container .sidebar h1 {
    font-size: 20px;
  }
  .Course_container .sidebar a {
    font-size: 10px;
    padding: 0px 0px;
  }
}
</style>

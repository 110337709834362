<template>
  <div class="footer">
    <div class="contact-info">
      <h3>Contact Us</h3>
      <p>thegymnasticsschool@gmail.com</p>
      <p>+91 79834 59434</p>
      <p>+91 93060 94565</p>
      <div class="social-icons">
        <a
          href="https://www.facebook.com/profile.php?id=61557713132401&mibextid=ZbWKwL"
          ><i class="fab fa-facebook-f"></i
        ></a>
        <a href="#"><i class="fab fa-twitter"></i></a>
        <a
          href="https://www.instagram.com/thegymnasticschool?igsh=MWZ6dGs2anppMm1pbg=="
          ><i class="fab fa-instagram"></i
        ></a>
        <a href="https://www.youtube.com/@thegymnasticschool"
          ><i class="fab fa-youtube"></i
        ></a>
      </div>
    </div>
    <div class="links">
      <a href="#">About us</a>
      <a href="#">Shop</a>
      <a href="#">FAQs</a>
      <a href="#">Commercial</a>
      <a href="#">Contact</a>
    </div>
    <div class="policies">
      <!-- <a onclick="openModal()">Login as staff</a> -->
      <button @click="openModal">Sign In</button>
      <a href="#">Careers at The Gymnastics School</a>
      <a href="#">Terms & Conditions</a>
      <a href="#">Privacy notices</a>
    </div>
    <div v-if="isModalOpen" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeModal">&times;</span>
        <h2>Sign In</h2>
        <form @submit.prevent="handleSubmit">
          <label for="username">Username:</label>
          <input type="text" v-model="username" id="username" required />
          <label for="password">Password:</label>
          <input type="password" v-model="password" id="password" required />
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isModalOpen: false,
      username: "",
      password: "",
    };
  },
  methods: {
    openModal() {
      console.log("reached here---------")
      this.isModalOpen = true;
      console.log(this.isModalOpen)
    },
    closeModal() {
      this.isModalOpen = false;
    },
    handleSubmit() {
      this.closeModal();
      this.$router.push('/testPage');
    },
  },
  name: "FooterComponent",
};
</script>

<style scoped>
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #0a2a4d;
  color: white;
}
.footer {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  background-image: url("../assets/footer_bg.jpg");
  /* background-color: #0a2a4d; */
}
.footer div {
  flex: 1;
  color: white;
}
.footer div h3 {
  margin-top: 0;
  color: white;
}
.footer div ul {
  list-style: none;
  padding: 0;
  color: white;
}
.footer div ul li {
  margin: 5px 0;
  color: white;
}
.footer .contact-info,
.footer .links,
.footer .policies {
  flex: 1;
  min-width: 200px;
  margin-bottom: 20px;
}
.footer .contact-info {
  text-align: left;
  color: white;
}
.footer .contact-info p {
  margin: 5px 0;
  color: white;
}
.footer .contact-info .social-icons {
  display: flex;
  color: white;
  justify-content: left;
  margin-top: 10px;
}
.footer .contact-info .social-icons a {
  color: white;
  margin-right: 10px;
  font-size: 24px;
  text-decoration: none;
}
.footer .links,
.footer .policies {
  text-align: center;
}

.footer .links a,
.footer .policies a {
  color: white;
  display: block;
  margin: 5px 0;
  text-decoration: none;
}
.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 300px; /* Could be more or less, depending on screen size */
  border-radius: 5px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

label {
  display: block;
  margin: 10px 0 5px;
}

input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button[type="submit"] {
  background-color: #4caf50; /* Green */
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #45a049;
}

@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    align-items: center;
  }

  .footer .contact-info,
  .footer .links,
  .footer .policies {
    text-align: center;
  }
  .footer .contact-info .social-icons {
    justify-content: center;
  }
}
</style>

<style>
body {
  margin: 0;
  font-family: Arial, sans-serif;
}
.Test_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100vh;
}
.sidebar {
  width: 35%;
  height: 100%;
  background-color: #343a40;
  color: white;
}
.sidebar a {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  text-decoration: none;
  color: white;
  font-size: 20px;
}
.sidebar a:hover {
  background-color: #FAF9F6;
  color: black;
  cursor: pointer;
}
.sidebar a i {
  margin-right: 10px;
}
.sidebar a .fas.fa-chevron-right {
  margin-left: auto;
}
.content {
  flex-grow: 1;
  display: flex;
  width: 100%;
  justify-content: top;
  align-items: center;
  /* background-color: white; */
}
.content img {
  max-width: 100%;
  height: auto;
}
.scrolling-container {
  padding-top: 0;
  height: 80%; /* Set the desired maximum height */
  width: 100%;
  overflow-y: auto; /* Enable vertical scrolling */
}
@media (max-width: 600px) {
  .Course_container .sidebar{
    width: 37%;
  }
  .Course_container .scrolling-container{
    width: 73%;
  }
  .Course_container .sidebar h1{
    font-size: 20px;
  }  
  .Course_container .sidebar a{
    font-size: 10px;
    padding: 0px 0px;
  }
}
</style>

<template>
  <Header />
  <div class="Test_container">
    <div class="sidebar">
      <div>
        <h1>Courses</h1>
        <ul>
          <li>
            <a @click="showContent('Profile')"
              >Profile</a
            >
          </li>
          <li>
            <a @click="showContent('Men PAT')"
              >Men PAT</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="scrolling-container">
      <p v-if="contentTitle === 'Profile' "> <Profile /> </p>
      <p v-else-if="contentTitle === 'Men PAT'"> <MenPAT /> </p>
      <p v-else> <Profile /> </p>
    </div>
  </div>
  <Footer />
</template>

<script>
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import Profile from "./tests/Profile.vue";
import MenPAT from "./tests/MenPAT.vue";
export default {
   data() {
                return {
                    contentTitle: 'Profile',
                    contentText: "Profile" ,
                }
            },
  name: "TestPage",
  
  methods: {
                showContent(section) {
                    switch(section) {
                        case 'Profile':
                            this.contentTitle = 'Profile';
                            break;
                        case 'Men PAT':
                            console.log("here==============")
                            this.contentTitle = 'Men PAT';
                            break;
                        default:
                            this.contentTitle = 'Developmental Gymnastics';
                    }
                }
            },
 components: {
    Header,
    Footer,
    Profile,
    MenPAT
  },
};
</script>
import Home from './components/Home.vue'
import Teams from './components/OurTeam.vue'
import Signup from './components/Signup.vue'
import Courses from './components/Courses.vue'
import StudentProfile from './components/StudentProfile.vue'
import TestPage from './components/TestPage.vue'
import {createRouter,createWebHistory} from 'vue-router'

const routes=[
    {
        name: "Home",
        component: Home,
        path: '/'
    },
    {
        name: "Courses",
        component: Courses,
        path: '/course'
    },
    {
        name: "Teams",
        component: Teams,
        path: '/teams'
    },
    {
        name: "Signup",
        component: Signup,
        path: '/signup'
    },
    {
        name: "StudentProfile",
        component: StudentProfile,
        path: '/studentProfile'
    },
    {
        name: "TestPage",
        component: TestPage,
        path: '/testPage'
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router;
<template>
  <div class="containerPet">
    <div class="header">
      <h1>Physical Ability Test Results</h1>
      <div class="details">
        <label for="name">Name:</label>
        <input type="text" id="name" v-model="name" />
        <label for="city">City:</label>
        <input type="text" id="city" v-model="city" />
        <label for="date">Date:</label>
        <input type="date" id="date" v-model="date" />
        <label for="bd">Bd.:</label>
        <input type="text" id="bd" v-model="bd" />
        <label for="club">Club:</label>
        <input type="text" id="club" v-model="club" />
      </div>
    </div>

    <div class="section">
      <h2>Strength</h2>
      <table>
        <thead>
          <tr>
            <th>Exercise</th>
            <th>Achieved Points</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Long jump</td>
            <td><input type="number" v-model.number="strength.longJump" /></td>
          </tr>
          <tr>
            <td>Rope climb</td>
            <td><input type="number" v-model.number="strength.ropeClimb" /></td>
          </tr>
          <tr>
            <td>Sprint</td>
            <td><input type="number" v-model.number="strength.sprint" /></td>
          </tr>
          <tr>
            <td>Circles</td>
            <td><input type="number" v-model.number="strength.circles" /></td>
          </tr>
          <tr>
            <td>Ring hdst</td>
            <td><input type="number" v-model.number="strength.ringHdst" /></td>
          </tr>
          <tr>
            <td>Leg lifts</td>
            <td><input type="number" v-model.number="strength.legLifts" /></td>
          </tr>
          <tr>
            <td>V-sit</td>
            <td><input type="number" v-model.number="strength.vSit" /></td>
          </tr>
          <tr>
            <td>Planche</td>
            <td><input type="number" v-model.number="strength.planche" /></td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="section">
      <h2>Flexibility</h2>
      <table>
        <thead>
          <tr>
            <th>Exercise</th>
            <th>Achieved Points</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Fwd splits</td>
            <td><input type="number" v-model.number="flexibility.fwdSplits" /></td>
          </tr>
          <tr>
            <td>Side splits</td>
            <td><input type="number" v-model.number="flexibility.sideSplits" /></td>
          </tr>
          <tr>
            <td>Leg lift fwd</td>
            <td><input type="number" v-model.number="flexibility.legLiftFwd" /></td>
          </tr>
          <tr>
            <td>Leg lift swd</td>
            <td><input type="number" v-model.number="flexibility.legLiftSwd" /></td>
          </tr>
          <tr>
            <td>Bridge</td>
            <td><input type="number" v-model.number="flexibility.bridge" /></td>
          </tr>
          <tr>
            <td>Trunk bend</td>
            <td><input type="number" v-model.number="flexibility.trunkBend" /></td>
          </tr>
          <tr>
            <td>Trunk bend sit</td>
            <td><input type="number" v-model.number="flexibility.trunkBendSit" /></td>
          </tr>
          <tr>
            <td>Shoulder</td>
            <td><input type="number" v-model.number="flexibility.shoulder" /></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: '',
      city: '',
      date: '',
      bd: '',
      club: '',
      strength: {
        longJump: null,
        ropeClimb: null,
        sprint: null,
        circles: null,
        ringHdst: null,
        legLifts: null,
        vSit: null,
        planche: null,
      },
      flexibility: {
        fwdSplits: null,
        sideSplits: null,
        legLiftFwd: null,
        legLiftSwd: null,
        bridge: null,
        trunkBend: null,
        trunkBendSit: null,
        shoulder: null,
      },
    };
  },
  name: "MenPAT",
};
</script>

<style scoped>
.containerPet {
  background-color: transparent; /* Set background to transparent */
  padding: 20px;
}

.header {
  margin-bottom: 20px;
}
.header h1 {
    color: black;

}

.section {
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}
</style>
<template>
  <div class="container">
    <header>
      <h1>{{ name }}</h1>
      <h2>{{ title }}</h2>
    </header>
    <div class="profile">
      <img :src="profileImage" :alt="name" class="profile-img" />
      <div class="bio">
        <h3>About Me</h3>
        <p>{{ aboutMe }}</p>
      </div>
    </div>
    <div class="contact">
      <h3>Contact Information</h3>
      <p>Email: {{ email }}</p>
      <p>Phone: {{ phone }}</p>
    </div>
    <div class="classes">
      <h3>Classes Taught</h3>
      <ul>
        <li v-for="(classItem, index) in classes" :key="index">{{ classItem }}</li>
      </ul>
    </div>
    <div class="achievements">
      <h3>Achievements</h3>
      <ul>
        <li v-for="(achievement, index) in achievements" :key="index">{{ achievement }}</li>
      </ul>
    </div>
    <footer>
      <p>&copy; 2023 {{ name }}. All rights reserved.</p>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "Karan Arela",
      title: "Head Men Coach",
      profileImage: require("../../assets/karan.jpg"), // Replace with your image path
      aboutMe: "As a dedicated head men gymnastics coach in the gymnastics school, my mission is to inspire athletes to push their limits, build confidence, and achieve their full potential.",
      email: "Karan@gmail.com",
      phone: "9876543210",
      classes: [
        "Introduction to Gymnastics",
        "Fitness and Conditioning",
        "Outdoor Education",
        "Developmental gymnastics"
      ],
      achievements: [
        "National Physical Education Teacher of the Year (2022)",
        "Certified Gymnastics Instructor",
        "Organizer of Annual Sports Meet",
        "Published Articles on Health and Fitness in Educational Journals"
      ]
    };
  },
  name: 'TeacherProfile',
};
</script>

<style scoped>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  color: #333;
}

.container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

header {
  text-align: center;
  margin-bottom: 20px;
}

.profile {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profile-img {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin-right: 20px;
}

.bio {
  flex: 1;
}

.contact,
.classes,
.achievements {
  margin-bottom: 20px;
}

h3 {
  margin-bottom: 10px;
}

ul {
  list-style-type: none;
}

footer {
  text-align: center;
  margin-top: 20px;
  padding: 10px;
  background-color: #333;
  color: white;
  border-radius: 8px;
}

@media (max-width: 600px) {
  .profile {
    flex-direction: column;
    align-items: flex-start;
  }

  .profile-img {
    margin-bottom: 10px;
  }
}
</style>
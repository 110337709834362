<template>
  <div>
    <div class="MainHeader">
      <div class="header">
        <!-- <img
          src="../assets/logo.jpeg" 
          alt=""
        /> -->
        <h1>FUN FITNESS FRIENDSHIP FAMILY FOREVER</h1>
        
      </div>
      <div class="login" >
        <!-- <h3 > Login </h3> -->
        <!-- <button type = "button" @click="showLoginDialog"> Register </button> -->
        <!-- <i class='fas fa-user-alt' style='font-size:36px'  href="/signup"></i> -->
        <!-- <a href="/signup"><i class="fas fa-user-alt" style='font-size:36px;color: white'></i></a> -->
        <i class="fas fa-user-alt" style='font-size:36px;color: white ;cursor: pointer' @click="showLoginDialog()"></i>
      </div>
    </div>
    
    <div class="nav">
      <a href="/">HOME</a>
      <a href="/teams">Our Team</a>
      <a href="/course">Courses</a>
      <!-- <a href="#">Events</a> -->
      <!-- <a href="#">Membership</a> -->
    </div>
    <div v-if="loginDialog" class="dialog">
      <h3>Login</h3>
      <div class="text">
        <label for="User Id">User Id:</label>
        <input type="text" required />
      </div>
      <div class="text">
        <label for="password">Password:</label>
        <input
          type="password"
          id="psw"
          name="psw"
          pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
          required
        />
      </div>
      <div>
        <a href="/studentProfile"><button @click="signIn">Sign In</button></a>
      </div>
      <div>
        <a href="/signup"><button @click="signUp">New user? Sign Up</button></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loginDialog: false,
    };
  },
  methods:{
    showLoginDialog() {
      console.log("here---------")
      this.loginDialog = true;
    }  
  },
  name: "HeaderComponent",
};

</script>

<style scoped>
body {
  margin: 0;
  font-family: Arial, sans-serif;
}
.MainHeader {
  display: flex;
  background-color: #082a6a;
  color: #fff;
  text-align: center;
  padding: 20px 0;
  position: relative;
  justify-content: space-around;
}
.header {
  background-color: #082a6a;
  color: #fff;
  text-align: center;
  padding: 20px 0;
  position: relative;
}
.login {
  display: flex;
  background-color: #082a6a;
  color: #fff;
  text-align: top;
  align-items: right;
  padding: 20px 0;
  /* position: relative; */
}
.login h3 {
  color: white;
  margin-right: 20px;
  font-size: 23px;
}
.login h3:hover {
  background-color: white;
  margin-right: 20px;
  cursor: pointer;
  color: black;
  font-size: 27px;
}

.login button {
  font-size: 21px;
  margin-right: 20px;
  border-radius: 40%;
}

.login button:hover {
  background-color: white;
  margin-right: 20px;
  cursor: pointer;
  color: black;
  font-size: 23px;
}

.header img {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  margin: 0 auto;
  height: 90px;
}
.header h1 {
  color: white;
}
.nav {
  background-color: #f7e4e4;
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
}
.nav a {
  color: #4a8db7;
  text-decoration: none;
  font-size: 18px;
  margin: 0 20px;
  font-weight: bold;
}
.nav a:hover {
  color: #4a8db7;
  text-decoration: none;
  font-size: 22px;
  font-weight: bold;
}
.nav a.active {
  color: #4a8db7;
  font-size: 20px;
}
.dialog {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px 19px;
  margin-top: 10px;
  border-radius: 5px;
  z-index: 1000;
}
.dialog h3 {
  margin-top: 0;
  font-size: 26px;
}
.dialog button {
  font-size: 16px;
  margin-right: 20px;
  margin-top: 10px;
  color: white;
  background-color: #082a6a;
}
@media (max-width: 600px) {
  .header h1 {
    font-size: 18px;
    color: white;
  }
  .nav a {
    display: block;
    margin: 10px 0;
    font-size: 16px;
  }
}
</style>
